<template>
  <div>
    <div class="relative md:mx-0">
      <div
        :class="[
          isImgCarousel ? 'mix-blend-darken ' : 'min-h-carousel-default',
          { 'max-h-[225px] md:max-h-[437px]': !showNavigationBullets },
        ]"
      >
        <swiper-container
          ref="swiper"
          init="false"
          :centered-slides="false"
          :class="getTailwindClasses"
          @click="sendActiveIndex()"
        >
          <swiper-slide
            v-for="(item, index) in items"
            :key="index"
            class="h-auto"
            :class="itemVisibility(index)"
          >
            <slot :item="item" :index="index">
              <component
                :is="item.path ? nuxtLink : 'div'"
                :to="item.path ? localePath(item.path) : ''"
                class="m-auto w-full"
                :class="cursor"
              >
                <OptimizedImage
                  :class="imageClass"
                  :src="item.src"
                  :alt="item.alt || index.toString()"
                  :sizes="sizes"
                  :lazy="index > 0"
                  @click="emit('clicked-image', index)"
                />
              </component>
            </slot>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { register } from 'swiper/element';
import { Navigation, Pagination, Keyboard, Grid } from 'swiper/modules';
// TODO: Import css as text do not work the same in vite and in rollup
// This works in rollup:
// import swiperNavigation from 'swiper/element/css/navigation?raw';
// import swiperPagination from 'swiper/element/css/pagination?raw';
// This works in vite:
// import swiperNavigation from 'swiper/element/css/navigation?inline';
// import swiperPagination from 'swiper/element/css/pagination?inline';
// In order to se the bug you need to clear the cache and open in a new browser window.

const emit = defineEmits(['clicked-image', 'active-image']);

const props = defineProps({
  items: {
    type: Array,
    default: () => [], // [{ src: '', alt: '', path?: '' }]
  },
  selectedItemId: {
    type: String,
    default: null,
  },
  perView: {
    type: Number,
    default: 1,
  },
  perGroup: {
    type: Number,
    default: 1,
  },
  breakpoints: {
    type: Object,
    default: () => {},
  },
  imageClass: {
    type: String,
    default: '',
  },
  enableKeyboardNavigation: {
    type: Boolean,
    default: false,
  },
  showNavigationBullets: {
    type: Boolean,
    default: true,
  },
  navigationBulletPadding: {
    type: String,
    default: '60px',
  },
  showNavigationArrows: {
    type: Boolean,
    default: false,
  },
  isImgCarousel: {
    type: Boolean,
    default: true,
  },
  ssr: {
    type: Boolean,
    default: false,
  },
  cursor: {
    type: String,
    default: 'cursor-default',
  },
  selectedImageIndex: {
    type: Number,
    default: -1,
  },
  sizes: {
    type: String,
    default: 'md:225px lg:437px',
  },
  verticalGrid: {
    type: Boolean,
    default: false,
  },
});

const items = computed(() => props.items);
const selectedItemId = computed(() => props.selectedItemId);
const selectedIndex = computed(() => {
  let index = items.value?.findIndex((item: any) =>
    item?.id?.includes(selectedItemId.value)
  );

  if (props.selectedImageIndex !== -1) {
    index = props.selectedImageIndex;
  }

  return index === -1 ? 0 : index;
});
const bulletPadding = props.showNavigationBullets
  ? props.navigationBulletPadding
  : '0';
const showNavigationBullets = computed(() => props.showNavigationBullets);
const showNavigationArrows = computed(() => props.showNavigationArrows);
const enableKeyboardNavigation = computed(() => props.enableKeyboardNavigation);
const verticalGrid = computed(() => props.verticalGrid);

const nuxtLink = computed(() => resolveComponent('NuxtLink'));

const calculateBreakpoints = computed(() => {
  if (props.breakpoints) {
    const breakpoints: { [key: string]: any } = {};
    Object.keys(props.breakpoints).forEach((key) => {
      const breakpointValue = props.breakpoints[key].breakpoint;
      if (breakpointValue) {
        breakpoints[breakpointValue] = props.breakpoints[key];
      }
    });
    return breakpoints;
  }
  return {};
});

const swiperParams = {
  modules: [Navigation, Pagination, Keyboard, Grid],
  initialSlide: selectedIndex.value,
  pagination: {
    enabled: showNavigationBullets.value,
    hideOnClick: false,
    clickable: true,
  },
  navigation: showNavigationArrows.value,
  breakpoints: calculateBreakpoints.value,
  keyboard: {
    enabled: enableKeyboardNavigation.value,
  },
  grid: { rows: 1 },
  on: {
    init() {
      swiperInitialized.value = true;
    },
  },
  injectStyles: [
    // swiperNavigation,
    `:host {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev.swiper-button-hidden,
.swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-navigation-disabled .swiper-button-prev,
.swiper-navigation-disabled .swiper-button-next {
  display: none !important;
}
.swiper-button-prev svg,
.swiper-button-next svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
.swiper-rtl .swiper-button-prev svg,
.swiper-rtl .swiper-button-next svg {
  transform: rotate(180deg);
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.swiper-button-lock {
  display: none;
}
/* Navigation font start *//* Navigation font end */

VM5164:1 pageView
Carousel.vue:52 :host {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev.swiper-button-hidden,
.swiper-button-next.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-navigation-disabled .swiper-button-prev,
.swiper-navigation-disabled .swiper-button-next {
  display: none !important;
}
.swiper-button-prev svg,
.swiper-button-next svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
.swiper-rtl .swiper-button-prev svg,
.swiper-rtl .swiper-button-next svg {
  transform: rotate(180deg);
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.swiper-button-lock {
  display: none;
}`,
    // swiperPagination,
    `:host {
  /*
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0,0,0,0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  */
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-pagination-disabled > .swiper-pagination,
.swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet:only-child {
  display: none !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}
/* Fraction */
.swiper-pagination-fraction {
  color: var(--swiper-pagination-fraction-color, inherit);
}
/* Progress */
.swiper-pagination-progressbar {
  background: var(--swiper-pagination-progressbar-bg-color, rgba(0, 0, 0, 0.25));
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: var(--swiper-pagination-progressbar-size, 4px);
  left: 0;
  top: 0;
}
.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
  width: var(--swiper-pagination-progressbar-size, 4px);
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-lock {
  display: none;
}
`,
    `.swiper-button-prev svg, .swiper-button-next svg{
        display:none;
      }`,
  ],
};

if (verticalGrid.value)
  swiperParams.injectStyles.push(
    '.swiper-wrapper { display: flex; flex-direction: unset; flex-wrap: wrap; }'
  );

const swiper = ref();
register();
const swiperInitialized = ref(false);

onMounted(() => {
  Object.assign(swiper.value, swiperParams);
  swiper.value.initialize();
});

const sendActiveIndex = () => {
  if (swiper.value) emit('active-image', swiper.value.swiper.realIndex);
};

watch(
  () => selectedIndex.value,
  () => {
    if (selectedIndex.value >= 0 && swiper.value?.swiper) {
      swiper.value.swiper.slideTo(selectedIndex.value, 0);
    }
  }
);

// Adds tailwind classes to items for ssr
const getTailwindClasses = computed(() => {
  if (swiperInitialized.value) {
    return '';
  }
  if (props.breakpoints) {
    const classes: string[] = [];
    Object.keys(props.breakpoints).forEach((breakpoint) => {
      if (props.breakpoints[breakpoint].tailwind) {
        classes.push(props.breakpoints[breakpoint].tailwind);
      }
    });
    return `${classes.join(' ')} grid overflow-visible pb-12 sm:pb-16`;
  }
  return 'grid overflow-visible pb-12 sm:pb-16 grid-cols-1';
});

// Hides items that is not visible in the first slide (ssr)
const itemVisibility = (index: number) => {
  if (swiperInitialized.value) {
    return '';
  }
  if (selectedIndex.value) {
    // SwiperJS "initialSlide" will not work if anything is hidden with css before init
    return 'w-0';
  }
  if (props.breakpoints) {
    const classes: string[] = [];
    Object.keys(props.breakpoints).forEach((breakpoint) => {
      const breakpointConfig = props.breakpoints[breakpoint];
      const slides =
        breakpointConfig.ssrSlidesPerView || breakpointConfig.slidesPerView;

      if (index >= slides) {
        switch (breakpoint) {
          case 'xs':
            classes.push('xs:hidden');
            break;
          case 'sm':
            classes.push('sm:hidden');
            break;
          case 'md':
            classes.push('md:hidden');
            break;
          case 'lg':
            classes.push('lg:hidden');
            break;
          case 'xl':
            classes.push('xl:hidden');
            break;
          default:
            classes.push('');
        }
      } else {
        switch (breakpoint) {
          case 'xs':
            classes.push('xs:block');
            break;
          case 'sm':
            classes.push('sm:block');
            break;
          case 'md':
            classes.push('md:block');
            break;
          case 'lg':
            classes.push('lg:block');
            break;
          case 'xl':
            classes.push('xl:block');
            break;
          default:
            classes.push('block');
        }
      }
    });

    return classes.join(' ');
  }
  if (index !== 0) {
    return 'hidden';
  }
  return 'visible';
};
</script>

<style scoped>
swiper-container::part(container) {
  padding-bottom: v-bind(bulletPadding);
}
swiper-container::part(pagination) {
  top: unset;
  bottom: 0;
}
swiper-container::part(bullet) {
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgba(0, 0, 0, 0.54);
  opacity: 0.15;
}
swiper-container::part(bullet-active) {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.54);
}
swiper-container::part(button-next),
swiper-container::part(button-prev) {
  height: 2.5rem;
  width: 2.5rem;
  background-color: rgba(0, 0, 0, 0.06);
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 0.75rem;
  background-image: url('~/assets/pictograms/dynamic/chevron.svg?external');
}
swiper-container::part(button-prev) {
  left: 0;
}
swiper-container::part(button-next) {
  transform: rotate(180deg);
  right: 0;
}
</style>
